import { useState } from "react";

const SassForm = () => {
  const [htmlInput, setHtmlInput] = useState('');
  const [sassOutput, setSassOutput] = useState('');

  const handleHtmlInputChange = (e) => {
    setHtmlInput(e.target.value);
  };

  const generateSass = () => {
    // SASS形式での出力結果をこの変数に格納
    let output = "";

    // HTMLをDOMとして解析
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlInput, 'text/html');
    const allElements = doc.querySelectorAll('*[class]');

    let classStructure = {};

    allElements.forEach(el => {
      el.classList.forEach(className => {
        // コンポーネント、エレメント、モディファイアを識別
        let parts = className.split('__');
        let component = parts[0];
        let element = parts[1] ? parts[1].split('--')[0] : '';
        let modifier = parts[1] && parts[1].includes('--') ? parts[1].split('--')[1] : '';

        if (!classStructure[component]) {
          classStructure[component] = { elements: {}, modifiers: [] };
        }

        if (element) {
          if (!classStructure[component].elements[element]) {
            classStructure[component].elements[element] = { modifiers: [] };
          }
        }

        if (modifier) {
          if (element) {
            classStructure[component].elements[element].modifiers.push(modifier);
          } else {
            classStructure[component].modifiers.push(modifier);
          }
        }
      });
    });

    // classStructureをSASS出力に変換
    for (let component in classStructure) {
      output += `.${component} {\n`;
      if (classStructure[component].modifiers.length > 0) {
        classStructure[component].modifiers.forEach(modifier => {
          output += `  &--${modifier} {\n    @extend .${component};\n  }\n`;
        });
      }
      for (let element in classStructure[component].elements) {
        output += `  &__${element} {\n`;
        classStructure[component].elements[element].modifiers.forEach(modifier => {
          output += `    &--${modifier} {\n      @extend .${component}__${element};\n    }\n`;
        });
        output += `  }\n`;
      }
      output += `}\n\n`;
    }

    setSassOutput(output);
  };

  return (
    <>
      <div className="sass-form">
        <h1 className="sass-form__ttl">Sass × Dart × FLOCSS 自動変換</h1>

        <div className="sass-form__inner">
          <div className="sass-form__item">
            <h3 className="sass-form__item-ttl">HTMLを構造ペーストしてね</h3>
            <textarea
              value={htmlInput}
              onChange={handleHtmlInputChange}
              cols="30"
              rows="10"
              className="sass-form__txt-area--html"
            ></textarea>
          </div>

          <div className="sass-form__item">
            <h3 className="sass-form__item-ttl">出力されたものをコピーしてね</h3>
            <textarea
              value={sassOutput}
              cols="30"
              rows="10"
              className="sass-form__txt-area--sass"
              readOnly
            ></textarea>
          </div>
        </div>

        <button className="sass-form__btn" onClick={generateSass}>変換</button>
      </div>
    </>
  );
};

export { SassForm };
